<template>
  <div class="mt-4">
    <button v-if="!$guards.is_super_admin() && !$guards.is_Admin()"
      @click="$router.push({ name: 'New Product' })"

      class="mb-2 btn btn-default"
    >
      New Product
    </button>
    <div class="card shadow w-100 p-4">
      <data-table
        v-if="data_loaded"
        id="products_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
        :on_click="dtb_on_click"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/Datatable";
import { alert } from "../../utils/alertUtils";

export default {
  name: "Products",
  components: {
    "data-table": DataTable,

  },
  data() {
    return {
       root_img_url:process.env.VUE_APP_IMG_URL,
      th: this,
      data_loaded: false,
      products: [],
      user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [],
        columns: [
          {
            title: "Name",
            data: "name",
            render: (data) => {
              if (data.primeImageUrl) {
                let html = `<img src="${this.root_img_url+''+data.primeImageUrl}" class="avatar avatar-sm rounded-circle img-fluid mr-1"/> ${data.name}`;
                return html;
              }
              return data.name;
            },
          },
          {
            title: "Price",
            data: "price",
            render: (data) => `${this.$num_format(data["price"])} ${data["currency"]}`,
          },
          {
            title: "discount",
            data: "discount",
            render: (data) =>
              data["discount"] ? `${data["discount"]}%` : "No Discount",
          },
          {
            title: "Published",
            data: "visibilityStatus",
            render: (data) => {
              return data["visibilityStatus"] ? "YES" : "NO";
            },
          },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    dtb_on_click: function (row) {
      this.gotoGallery(row.uuid)
    },
    gotoGallery: function (uuid) {
      this.$router.push({ name: "Product Gallery", params: { uuid } });
    },
    fetch_products: function () {
      if(this.user_profile.entity!=='undefine'){
        axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.user_profile.entity.uuid}/products`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((res) => {
          this.products = res.data;
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
        });
      }else{
        this.products=[]
        this.dtb_options.data=[]
      }
      
    },

     fetch_all_products: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/admin/products`
          ,{
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((res) => {
          this.products = res.data;
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
        });
    },
  },
  created() {
    if(this.$guards.is_super_admin()|| this.$guards.has_role('Admin')){
      this.fetch_all_products()
    }else{
       this.fetch_products();
    }
  },
};
</script>

<style>
</style>